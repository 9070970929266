import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu, Home, Calendar, Info, Smartphone, Tv, LucideIcon } from 'lucide-react';

interface HeaderProps {
  onMenuToggle: () => void;
}

interface NavItem {
  path: string;
  label: string;
  icon: LucideIcon;
}

const Header: React.FC<HeaderProps> = ({ onMenuToggle }) => {
  const location = useLocation();

  const navItems: NavItem[] = [
    { path: '/', label: 'Hem', icon: Home },
    { path: '/kalender', label: 'Kalender', icon: Calendar },
    { path: '/app', label: 'App', icon: Smartphone },
    { path: '/tv', label: 'TV', icon: Tv },
    { path: '/info', label: 'Info', icon: Info },
  ];

  return (
    <header className="fixed top-0 left-0 right-0 bg-secondary p-3 z-50 shadow-md h-16">
      <div className="flex flex-row justify-between items-center max-w-7xl mx-auto h-full px-4 lg:px-8">
        <Link to="/" className="flex items-center">
          <img 
            src="/logo-mjolby-moske.png" 
            alt="Mjölby Moské Logo" 
            className="h-10 w-auto mr-2 shadow-lg rounded-xl filter drop-shadow-[0_0_8px_rgba(255,255,255,0.5)]"
          />
          <div className="text-2xl sm:text-3xl font-arial-rounded-bold text-white">
            Mjölby Moské
          </div>
        </Link>

        {/* Navigation for larger screens */}
        <nav className="hidden md:flex items-center space-x-6">
          {navItems.map(({ path, label, icon: Icon }) => (
            <Link
              key={path}
              to={path}
              className={`flex items-center space-x-2 px-3 py-2 rounded-lg transition-colors duration-200 
                ${location.pathname === path 
                  ? 'bg-primary text-white' 
                  : 'text-white/80 hover:bg-primary/30'}`}
            >
              <Icon className="w-5 h-5" />
              <span className="font-arial-rounded">{label}</span>
            </Link>
          ))}
        </nav>

        {/* Menu button for mobile */}
        <button 
          onClick={onMenuToggle}
          className="md:hidden text-white focus:outline-none"
          aria-label="Toggle menu"
        >
          <Menu size={24} />
        </button>
      </div>
    </header>
  );
};

export default Header;