import React from 'react';
import { motion } from 'framer-motion';
import { Calendar, Clock, MapPin, AlertCircle, Users } from 'lucide-react';

const NewsSection: React.FC = () => {
  return (
    <motion.div
      className="bg-secondary rounded-lg p-6 mb-8"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="flex items-center mb-4 border-b border-white/20 pb-2">
        <h2 className="text-xl sm:text-2xl font-arial-rounded-bold">Senaste Nytt</h2>
      </div>

      <div className="space-y-4">
        {/* Title */}
        <div className="border-l-4 border-orange-400 pl-3">
          <h3 className="text-lg sm:text-xl font-arial-rounded-bold">
            Ramadan 2025 - Tarawih-böner
          </h3>
          <p className="text-sm text-white/90 mt-1">
            Vi välkomnar den välsignade månaden Ramadan! Här är viktig information om Tarawih-bönerna:
          </p>
        </div>

        {/* Info Grid */}
        <div className="grid gap-3 sm:grid-cols-2">
          {/* Schedule */}
          <div className="bg-primary/20 rounded-lg p-3">
            <div className="flex items-center gap-2 mb-1">
              <Calendar className="w-4 h-4 text-orange-300" />
              <h4 className="font-arial-rounded-bold">Schema</h4>
            </div>
            <p className="text-sm text-white/90 ml-6">
              Tarawih-böner utförs alla dagar i veckan
            </p>
          </div>

          {/* Times */}
          <div className="bg-primary/20 rounded-lg p-3">
            <div className="flex items-center gap-2 mb-1">
              <Clock className="w-4 h-4 text-orange-300" />
              <h4 className="font-arial-rounded-bold">Tider</h4>
            </div>
            <ul className="text-sm text-white/90 ml-6 space-y-0.5">
              <li>• Tillgång till hallen: 19:30</li>
              <li>• Program: Azan → Isha → Tarawih</li>
            </ul>
          </div>
        </div>

        {/* Location */}
        <div className="bg-primary/20 rounded-lg p-3">
          <div className="flex items-center gap-2 mb-2">
            <MapPin className="w-4 h-4 text-orange-300" />
            <h4 className="font-arial-rounded-bold">Plats</h4>
          </div>
          
          <div className="space-y-3 ml-6">
            <div className="pb-2 border-b border-white/10">
              <div className="flex items-center gap-2">
                <p className="font-arial-rounded-bold text-sm">Kungshögahallen</p>
                <p className="text-xs text-white/80">mån, ons, tors, fre, lör, sön</p>
              </div>
              <div className="flex items-center mt-1 text-xs text-white/80">
                <p>Gymnasievägen 1, 595 34 Mjölby</p>
                <a 
                  href="https://maps.app.goo.gl/PtUKYTFcdgR7hzXX8"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ml-2 text-blue-300 hover:text-blue-200 underline"
                >
                  Visa på karta
                </a>
              </div>
            </div>
            
            <div className="flex items-center gap-2">
              <p className="font-arial-rounded-bold text-sm">Moskén</p>
              <p className="text-xs text-white/80">tisdagar</p>
            </div>
          </div>
        </div>

        {/* Additional Info */}
        <div className="bg-primary/20 rounded-lg p-3">
          <div className="flex gap-3">
            <Users className="w-4 h-4 text-orange-300 flex-shrink-0 mt-1" />
            <div className="space-y-2 text-sm">
              <p className="text-white/90">
                <span className="font-arial-rounded-bold">För hela familjen:</span> Vi välkomnar alla – män, kvinnor och barn – att delta i Tarawih-bönerna på båda platserna.
              </p>
              <p className="text-white/90">
                <span className="font-arial-rounded-bold">Recitation:</span> Varje kväll kommer en hel juz' av Koranen att reciteras under Tarawih bön.
              </p>
            </div>
          </div>
        </div>

        {/* Important Notice */}
        <div className="bg-red-900/20 p-3 rounded-lg border-l-4 border-red-400">
          <p className="text-sm font-arial-rounded-bold flex items-center">
            <AlertCircle className="w-4 h-4 mr-2" />
            Kom ihåg att ta med bönemattor med er.
          </p>
        </div>
      </div>
    </motion.div>
  );
};

export default NewsSection;