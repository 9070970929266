import React, { useEffect, useState } from 'react';

interface NextPrayerProps {
  nextPrayer: [string, string, boolean] | null;
  isToday: boolean;
  isLoading: boolean;
  selectedDate: Date;
}

const formatTime = (time: string): string => {
  return time.slice(0, -3);
};

const NextPrayer: React.FC<NextPrayerProps> = ({ nextPrayer, isToday, isLoading, selectedDate }) => {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setAnimate(true);
    const timer = setTimeout(() => setAnimate(false), 300);
    return () => clearTimeout(timer);
  }, [selectedDate]);

  if (!nextPrayer) return null;

  const [prayerName, prayerTime, isNextDay] = nextPrayer;

  const getPrayerLabel = () => {
    if (isToday) {
      if (isNextDay) return "Fajr (imorgon";
      return prayerName === 'Sunrise' ? "Nästa" : "Nästa bön";
    }
    return null;
  };  

  const formatDate = (date: Date) => {
    return date.toLocaleDateString('sv-SE', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
  };

  const translatePrayerName = (name: string) => {
    const translations: { [key: string]: string } = {
      'Fajr': 'Fajr',
      'Sunrise': 'Soluppgång',
      'Dhuhr': 'Dhuhr',
      'Asr': 'Asr',
      'Maghrib': 'Maghrib',
      'Isha': 'Isha'
    };
    return translations[name] || name;
  };

  return (
    <div className={`bg-secondary rounded-lg p-3 mb-4 sm:mb-6 transition-all duration-300 
      ${isLoading ? 'opacity-50' : 'opacity-100'} 
      ${animate ? 'scale-105 opacity-70' : 'scale-100 opacity-100'}`}>
      {getPrayerLabel() && (
        <div className="text-lg sm:text-xl mb-1 font-arial-rounded">
          {getPrayerLabel()}
        </div>
      )}
      <div className="text-4xl sm:text-4xl font-arial-rounded-bold">
        {`${translatePrayerName(prayerName)} ${formatTime(prayerTime)}`}
      </div>
      <div className={`font-arial-rounded ${isToday ? 'text-sm' : 'text-base sm:text-lg'} mt-1`}>
        {formatDate(selectedDate)}
      </div>
    </div>
  );
};

export default NextPrayer;