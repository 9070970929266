export interface PrayerTimes {
  Fajr: string;
  Sunrise: string;
  Dhuhr: string;
  Asr: string;
  Maghrib: string;
  Isha: string;
  [key: string]: string;
}

export interface PrayerTimesData {
  [date: string]: PrayerTimes;
}

export const getNextPrayer = (selectedDate: Date, currentTime: Date, prayerTimesData: PrayerTimesData): [string, string, boolean] | null => {
  const dateString = selectedDate.toISOString().split('T')[0];
  const times = prayerTimesData[dateString];
  if (!times) return null;

  const currentMinutes = currentTime.getHours() * 60 + currentTime.getMinutes();
  const isSelectedDateToday = selectedDate.toDateString() === currentTime.toDateString();

  const prayerTimes = [
    { name: 'Fajr', time: times.Fajr },
    { name: 'Sunrise', time: times.Sunrise },
    { name: 'Dhuhr', time: times.Dhuhr },
    { name: 'Asr', time: times.Asr },
    { name: 'Maghrib', time: times.Maghrib },
    { name: 'Isha', time: times.Isha },
  ];

  // If it's not today, return Fajr of the selected date
  if (!isSelectedDateToday) {
    return ['Fajr', times.Fajr, false];
  }

  // Find the next prayer for today
  for (const prayer of prayerTimes) {
    const [hours, minutes] = prayer.time.split(':').map(Number);
    const prayerMinutes = hours * 60 + minutes;
    if (prayerMinutes > currentMinutes) {
      return [prayer.name, prayer.time, false];
    }
  }

  // If all prayers have passed, check for tomorrow's Fajr
  const nextDate = new Date(selectedDate);
  nextDate.setDate(nextDate.getDate() + 1);
  const nextDateString = nextDate.toISOString().split('T')[0];
  const nextDayTimes = prayerTimesData[nextDateString];

  if (nextDayTimes && nextDayTimes.Fajr) {
    return ['Fajr', nextDayTimes.Fajr, true];
  }

  // If no next prayer found (shouldn't happen in normal cases)
  return null;
};

export const getCurrentPrayer = (selectedDate: Date, currentTime: Date, prayerTimesData: PrayerTimesData): string | null => {
  const dateString = selectedDate.toISOString().split('T')[0];
  const times = prayerTimesData[dateString];
  if (!times) return null;

  const currentMinutes = currentTime.getHours() * 60 + currentTime.getMinutes();
  const prayers = Object.entries(times);

  for (let i = prayers.length - 1; i >= 0; i--) {
    const [name, time] = prayers[i];
    const [hours, minutes] = time.split(':').map(Number);
    if (currentMinutes >= hours * 60 + minutes) {
      return name;
    }
  }

  return prayers[prayers.length - 1][0]; // Return last prayer of previous day
};