import React, { useState, useEffect, useCallback } from 'react';
import { format } from 'date-fns';
import { sv } from 'date-fns/locale';
import { motion } from 'framer-motion';
import { WeeklyPrayerTime, PrayerTime } from '../utils/types';
import { PrayerTimesData, getNextPrayer, getCurrentPrayer } from '../utils/prayerTimeUtils';
import { fetchPrayerTimes, fetchWeeklyPrayerTimes } from '../utils/api';
import { toHijri } from 'hijri-converter';

interface ArabicNames {
  [key: string]: string;
}

const TVDisplayPage: React.FC = () => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [prayerTimesData, setPrayerTimesData] = useState<PrayerTimesData>({});
  const [weeklyPrayerTimes, setWeeklyPrayerTimes] = useState<WeeklyPrayerTime | null>(null);
  const [nextPrayer, setNextPrayer] = useState<[string, string, boolean] | null>(null);
  const [currentPrayer, setCurrentPrayer] = useState<string | null>(null);
  const [showPulsingBlur, setShowPulsingBlur] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const arabicPrayers: ArabicNames = {
    'Fajr': 'فجر',
    'Dhuhr': 'ظهر',
    'Asr': 'عصر',
    'Maghrib': 'مغرب',
    'Isha': 'عشاء'
  };

  const arabicHeaders: ArabicNames = {
    'Bön': 'صلاة',
    'Adhan': 'أذان',
    'Iqamah': 'إقامة'
  };

  // Load prayer times
  const loadPrayerTimes = useCallback(async () => {
    try {
      const tomorrow = new Date(selectedDate);
      tomorrow.setDate(tomorrow.getDate() + 1);
      
      const startDate = format(selectedDate, 'yyyy-MM-dd');
      const endDate = format(tomorrow, 'yyyy-MM-dd');
      const prayerTimes = await fetchPrayerTimes(startDate, endDate);
      
      const formattedData: PrayerTimesData = {};
      prayerTimes.forEach((pt: PrayerTime) => {
        formattedData[pt.date] = {
          Fajr: pt.fajr,
          Sunrise: pt.sunrise,
          Dhuhr: pt.dhuhr,
          Asr: pt.asr,
          Maghrib: pt.maghrib,
          Isha: pt.isha,
        };
      });
  
      setPrayerTimesData(formattedData);
    } catch (error) {
      console.error("Error loading prayer times:", error);
    }
  }, [selectedDate]);

  // Load weekly prayer times
  const loadWeeklyPrayerTimes = useCallback(async () => {
    try {
      const data = await fetchWeeklyPrayerTimes();
      setWeeklyPrayerTimes(data);
    } catch (error) {
      console.error("Error fetching weekly prayer times:", error);
    }
  }, []);

  const todaysPrayerTimes = prayerTimesData[selectedDate.toISOString().split('T')[0]];

  const handlePulsingBlur = useCallback((currentTime: Date) => {
    if (!currentPrayer || !todaysPrayerTimes[currentPrayer]) return;

    const status = getPhoneRestrictionStatus(
      currentPrayer,
      todaysPrayerTimes[currentPrayer],
      currentTime
    );

    if (status === 'no-phone') {
      // Show blur effect for first 10 seconds of each minute
      const seconds = currentTime.getSeconds();
      if (seconds < 10) {
        setShowPulsingBlur(true);
      } else {
        setShowPulsingBlur(false);
      }
    } else {
      setShowPulsingBlur(false);
    }
  }, [currentPrayer, todaysPrayerTimes]);

  // Initial load
  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      await Promise.all([loadPrayerTimes(), loadWeeklyPrayerTimes()]);
      setIsLoading(false);
    };
    loadData();
  }, [loadPrayerTimes, loadWeeklyPrayerTimes]);

  // Update time and prayers every second
  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date();
      setCurrentTime(now);
      
      // Check if date has changed (crossed midnight)
      if (selectedDate.getDate() !== now.getDate()) {
        loadPrayerTimes();
        setSelectedDate(now);
      }
      
      const dateString = selectedDate.toISOString().split('T')[0];
      if (prayerTimesData[dateString]) {
        setCurrentPrayer(getCurrentPrayer(selectedDate, now, prayerTimesData));
        setNextPrayer(getNextPrayer(selectedDate, now, prayerTimesData));
        handlePulsingBlur(now);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [selectedDate, prayerTimesData, handlePulsingBlur, loadPrayerTimes]);

  const translatePrayer = (prayer: string): string => {
    const translations: { [key: string]: string } = {
      'Fajr': 'Fajr',
      'Sunrise': 'Soluppgång',
      'Dhuhr': 'Dhuhr',
      'Asr': 'Asr',
      'Maghrib': 'Maghrib',
      'Isha': 'Isha'
    };
    return translations[prayer] || prayer;
  };

  const getMinutesUntilNext = (nextTime: string): number => {
    if (!nextTime) return 0;
    const [hours, minutes] = nextTime.split(':').map(Number);
    const nextPrayerTime = new Date();
    nextPrayerTime.setHours(hours, minutes, 0);
    
    // Handle next day case
    if (nextPrayerTime < currentTime) {
      nextPrayerTime.setDate(nextPrayerTime.getDate() + 1);
    }
    
    return (nextPrayerTime.getTime() - currentTime.getTime()) / (1000 * 60);
  };

  const calculateIqamahTime = (adhanTime: string, iqamahOffset: string): string => {
    if (!iqamahOffset.startsWith('+')) return iqamahOffset;

    const [hours, minutes] = adhanTime.split(':').map(Number);
    const offsetMinutes = parseInt(iqamahOffset.match(/\d+/)?.[0] || '0');
    
    const adhanDate = new Date();
    adhanDate.setHours(hours, minutes + offsetMinutes, 0);
    
    return format(adhanDate, 'HH:mm');
  };

  const formatIqamahTime = (prayerName: string, adhanTime: string): string => {
    if (!weeklyPrayerTimes) return '-';
    const prayerMap: { [key: string]: string } = {
      'Fajr': weeklyPrayerTimes.fajr,
      'Dhuhr': weeklyPrayerTimes.dhuhr,
      'Asr': weeklyPrayerTimes.asr,
      'Maghrib': weeklyPrayerTimes.maghrib,
      'Isha': weeklyPrayerTimes.isha
    };
    const iqamahTime = prayerMap[prayerName];
    return iqamahTime ? calculateIqamahTime(adhanTime, iqamahTime) : '-';
  };

  const getHijriDate = (date: Date) => {
    const hijri = toHijri(date.getFullYear(), date.getMonth() + 1, date.getDate());
    const hijriMonths = [
      'Muharram', 'Safar', 'Rabi al-Awwal', 'Rabi al-Thani',
      'Jumada al-Ula', 'Jumada al-Thani', 'Rajab', 'Shaban',
      'Ramadan', 'Shawwal', 'Dhu al-Qadah', 'Dhu al-Hijjah'
    ];
    return {
      day: hijri.hd,
      month: hijriMonths[hijri.hm - 1],
      monthNum: hijri.hm,
      year: hijri.hy
    };
  };

  const formatTimeLeft = (minutes: number): string => {
    const totalSeconds = Math.floor(minutes * 60);
    const hours = Math.floor(totalSeconds / 3600);
    const mins = Math.floor((totalSeconds % 3600) / 60);
    const secs = totalSeconds % 60;

    if (hours > 0) {
      return `${hours}:${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    }
    return `${mins}:${secs.toString().padStart(2, '0')} min`;
  };

  // Add after your existing utility functions
  const getPhoneRestrictionStatus = (
    prayer: string | null,
    adhanTime: string | null,
    currentTime: Date
  ): 'none' | 'no-phone' | 'blackout' => {
    if (!prayer || !adhanTime) return 'none';
    
    const [hours, minutes] = adhanTime.split(':').map(Number);
    const adhanDate = new Date(currentTime);
    adhanDate.setHours(hours, minutes, 0);
    
    const iqamahTime = formatIqamahTime(prayer, adhanTime);
    if (iqamahTime === '-') return 'none';
    
    const [iqamahHours, iqamahMinutes] = iqamahTime.split(':').map(Number);
    const iqamahDate = new Date(currentTime);
    iqamahDate.setHours(iqamahHours, iqamahMinutes, 0);
    
    const blackoutEndDate = new Date(iqamahDate);
    blackoutEndDate.setMinutes(blackoutEndDate.getMinutes() + 12);

    if (currentTime >= iqamahDate && currentTime <= blackoutEndDate) {
      return 'blackout';
    } else if (currentTime >= adhanDate && currentTime <= blackoutEndDate) {
      return 'no-phone';
    }
    
    return 'none';
  };

  const isTimeBetweenAdhanAndIqamah = (
    prayer: string,
    adhanTime: string,
    currentTime: Date
  ): boolean => {
    const [hours, minutes] = adhanTime.split(':').map(Number);
    const adhanDate = new Date(currentTime);
    adhanDate.setHours(hours, minutes, 0);
    
    const iqamahTime = formatIqamahTime(prayer, adhanTime);
    if (iqamahTime === '-') return false;
    
    const [iqamahHours, iqamahMinutes] = iqamahTime.split(':').map(Number);
    const iqamahDate = new Date(currentTime);
    iqamahDate.setHours(iqamahHours, iqamahMinutes, 0);
    
    return currentTime >= adhanDate && currentTime <= iqamahDate;
  };

  if (isLoading) {
    return (
      <div className="min-h-screen bg-primary flex items-center justify-center">
        <div className="text-white text-3xl">Laddar bönetider...</div>
      </div>
    );
  }

  if (!todaysPrayerTimes) {
    return (
      <div className="min-h-screen bg-primary flex items-center justify-center">
        <div className="text-white text-3xl">Inga bönetider tillgängliga.</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen gradient-bg p-4 text-white flex flex-col h-screen">
      <div className="max-w-4xl mx-auto w-full px-2 sm:px-4 lg:px-16 flex flex-col h-full">
        {/* Time and Date Header */}
        <div className="text-center mb-4 h-1/4">
          <motion.div 
            initial={{ scale: 0.9 }}
            animate={{ scale: 1 }}
            className="text-5xl sm:text-7xl lg:text-8xl font-arial-rounded-bold mb-2 tracking-wider"
            style={{ textShadow: '0 0 20px rgba(255,255,255,0.2)' }}
          >
            {format(currentTime, 'HH:mm:ss')}
          </motion.div>
          <div className="flex flex-col gap-1">
            <div className="text-lg sm:text-xl lg:text-2xl font-arial-rounded capitalize text-white/90">
              {format(currentTime, 'EEEE d MMMM yyyy', { locale: sv })}
            </div>
            <div className="text-lg sm:text-xl lg:text-2xl font-arial-rounded text-white/90">
              {(() => {
                const hijri = getHijriDate(currentTime);
                return (
                  <div className="bg-white/10 rounded-lg py-1 px-4 inline-block">
                    {hijri.day} {hijri.month} {hijri.year}
                  </div>
                );
              })()}
            </div>
          </div>
        </div>
  
        {/* Next Prayer Banner */}
        {nextPrayer && (
          <motion.div 
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            className="bg-secondary/30 backdrop-blur-sm rounded-xl p-3 mb-4 text-center shadow-lg"
          >
            <div className="text-xl sm:text-2xl lg:text-3xl font-arial-rounded-bold flex items-center justify-center gap-4">
              {(() => {
                const currentPrayerTime = currentPrayer ? todaysPrayerTimes[currentPrayer] : null;
                const isWaitingForIqamah = currentPrayer && currentPrayerTime && 
                  isTimeBetweenAdhanAndIqamah(currentPrayer, currentPrayerTime, currentTime);
                if (isWaitingForIqamah && currentPrayer) {
                  const iqamahTime = formatIqamahTime(currentPrayer, todaysPrayerTimes[currentPrayer]);
                  const minutesToIqamah = getMinutesUntilNext(iqamahTime);
                  return (
                    <>
                      <span className="text-accent-orange">Iqamah för {translatePrayer(currentPrayer)}</span>
                      <span className="text-accent-orange">om</span>
                      <div className="bg-accent-orange/20 rounded-lg px-4 py-1 shadow-inner">
                        <span className="font-mono text-2xl sm:text-3xl lg:text-4xl text-white">
                          {formatTimeLeft(minutesToIqamah)}
                        </span>
                      </div>
                    </>
                  );
                } else {
                  const [prayerName, prayerTime, isTomorrow] = nextPrayer;
                  return (
                    <>
                      <span className="text-accent-orange">
                        {translatePrayer(prayerName)}
                        {isTomorrow && <span className="text-lg ml-2">(imorgon)</span>}
                      </span>
                      <span className="text-accent-orange">om</span>
                      <div className="bg-accent-orange/20 rounded-lg px-4 py-1 shadow-inner">
                        <span className="font-mono text-2xl sm:text-3xl lg:text-4xl text-white">
                          {formatTimeLeft(getMinutesUntilNext(prayerTime))}
                        </span>
                      </div>
                    </>
                  );
                }
              })()}
            </div>
          </motion.div>
        )}
  
        {/* Prayer Times Grid */}
        <div className="grid grid-cols-1 gap-2 flex-grow">
          {/* Headers Row */}
          <div className="bg-secondary/30 backdrop-blur-sm rounded-xl p-1 sm:p-2 lg:p-3 grid grid-cols-3 text-center">
            {['Bön', 'Adhan', 'Iqamah'].map((header) => (
              <div key={header} className="text-sm sm:text-base lg:text-lg font-arial-rounded-bold flex flex-col gap-1">
                <span className="text-sm sm:text-base lg:text-lg text-white/70 font-bold">{arabicHeaders[header]}</span>
                <span>{header}</span>
              </div>
            ))}
          </div>
  
          {/* Prayer Times Rows */}
          <div className="flex-grow grid grid-cols-1 gap-2 auto-rows-fr">
            {Object.entries(todaysPrayerTimes).map(([prayer, time]) => (
              prayer !== 'Sunrise' && (
                <motion.div 
                  key={prayer}
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.3 }}
                  className={`grid grid-cols-3 text-center rounded-xl p-1 sm:p-2 lg:p-3 text-sm sm:text-base lg:text-lg items-center
                    ${currentPrayer === prayer 
                      ? 'bg-gradient-to-r from-accent-orange/20 to-accent-gold/20 border border-accent-orange/30' 
                      : 'bg-secondary/30 backdrop-blur-sm'}
                    hover:bg-white/20 transition-colors duration-200`}
                >
                  <div className="font-arial-rounded flex flex-col">
                    <span className={`text-sm sm:text-base lg:text-lg ${currentPrayer === prayer ? 'text-accent-orange' : 'text-white/70'} font-bold`}>
                      {arabicPrayers[prayer]}
                    </span>
                    <span className={`${currentPrayer === prayer ? 'text-accent-orange' : ''} font-bold`}>
                      {translatePrayer(prayer)}
                    </span>
                  </div>
                  <div className="font-arial-rounded font-bold">
                    {time.slice(0, 5)}
                  </div>
                  <div className="font-arial-rounded font-bold">
                    {formatIqamahTime(prayer, time)}
                  </div>
                </motion.div>
              )
            ))}
          </div>
        </div>
  
        {/* Jumah Times */}
        {weeklyPrayerTimes && (
          <motion.div 
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            className="mt-4 bg-secondary/30 backdrop-blur-sm rounded-xl p-3 text-center shadow-lg"
          >
            <div className="flex flex-col sm:flex-row items-center justify-center gap-2 text-lg sm:text-xl">
              <span className="font-arial-rounded-bold">Jumah:</span>
              <span className="px-3 py-1 bg-secondary/30 rounded-lg">Khutba {weeklyPrayerTimes.djuma_khutba}</span>
              <span className="mx-2 hidden sm:inline">|</span>
              <span className="px-3 py-1 bg-secondary/30 rounded-lg">Bön {weeklyPrayerTimes.djuma_prayer}</span>
            </div>
          </motion.div>
        )}
  
        {/* Phone Restriction Overlay */}
        {(() => {
          const restrictionStatus = currentPrayer 
            ? getPhoneRestrictionStatus(
                currentPrayer,
                todaysPrayerTimes[currentPrayer],
                currentTime
              )
            : 'none';
          if (restrictionStatus === 'none') return null;
          return (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ 
                opacity: 1,
                backdropFilter: restrictionStatus === 'blackout' || showPulsingBlur ? 'blur(10px)' : 'blur(0px)'
              }}
              transition={{ duration: 2 }}
              className={`fixed inset-0 flex items-center justify-center z-50
                ${(restrictionStatus === 'blackout' || showPulsingBlur) ? 'bg-secondary/50' : 'bg-transparent'}`}
            >
              <motion.div
                initial={{ scale: 0.8 }}
                animate={{ 
                  scale: restrictionStatus === 'blackout' || showPulsingBlur ? 1 : 0.8,
                  opacity: restrictionStatus === 'blackout' || showPulsingBlur ? 1 : 0.3
                }}
                transition={{ duration: 2 }}
                className="relative flex flex-col items-center"
              >
                <img 
                  src="/no-cellphone.png" 
                  alt="No cellphones" 
                  className={`w-32 sm:w-48 h-32 sm:h-48 ${
                    restrictionStatus === 'blackout' || showPulsingBlur ? 'opacity-70' : 'opacity-30'
                  }`}
                />
                <div className="mt-8 text-white text-xl sm:text-2xl font-arial-rounded text-center">
                  {restrictionStatus === 'blackout' 
                    ? 'Bön pågår'
                    : showPulsingBlur ? 'Vänligen stäng av mobilen' : ''}
                </div>
              </motion.div>
            </motion.div>
          );
        })()}
  
        {/* Footer Text */}
        <div className="mt-4 text-center text-white/70 text-sm sm:text-base lg:text-lg font-arial-rounded">
          Kultur och Kunskap Förening • www.mjolbymoske.se
        </div>
      </div>
    </div>
  );
};

export default TVDisplayPage;