import React, { useState, useEffect, useCallback, useRef } from "react";
import DateSelector from "./components/DateSelector";
import NextPrayer from "./components/NextPrayer";
import PrayerTimeGrid from "./components/PrayerTimeGrid";
import Header from "./components/Header";
import SlideMenu from "./components/SlideMenu";
import ContactInfo from "./components/ContactInfo";
import InfoPage from "./pages/InfoPage";
import CalendarPage from "./pages/CalendarPage";
import AppDownloadPage from "./pages/AppDownloadPage";
import WeeklyPrayerTimes from "./components/WeeklyPrayerTimes";
import NewsSection from './components/NewsSection';

import {
  PrayerTimesData,
  getNextPrayer,
  getCurrentPrayer,
} from "./utils/prayerTimeUtils";

import { fetchPrayerTimes, fetchWeeklyPrayerTimes } from "./utils/api";
import { PrayerTime, WeeklyPrayerTime } from "./utils/types";

import { format, startOfMonth, endOfMonth, getMonth, getYear } from "date-fns";
import {
  getCachedPrayerTimes,
  setCachedPrayerTimes,
  clearCachedPrayerTimes,
} from "./utils/cache";
import MosqueMap from "./components/MosqueMap";

// Function to check if a given date is in the current month
const isCurrentMonth = (date: Date): boolean => {
  const now = new Date();
  return getMonth(date) === getMonth(now) && getYear(date) === getYear(now);
};

const PrayerTimesApp: React.FC = () => {
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [currentTime, setCurrentTime] = useState<Date>(new Date());
  const [isLoading, setIsLoading] = useState(true);
  const [currentPrayer, setCurrentPrayer] = useState<string | null>(null);
  const [prayerTimesData, setPrayerTimesData] = useState<PrayerTimesData>({});
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Weekly prayer times
  const [weeklyPrayerTimes, setWeeklyPrayerTimes] = useState<WeeklyPrayerTime | null>(null);
  const [isWeeklyLoading, setIsWeeklyLoading] = useState<boolean>(true);

  // Track current month/year
  const [currentMonthYear, setCurrentMonthYear] = useState<{ month: number; year: number }>({
    month: getMonth(new Date()),
    year: getYear(new Date()),
  });

  // In-memory cache for non-current months
  const inMemoryCache = useRef<Map<string, PrayerTimesData>>(new Map());

  // Load prayer times
  const loadPrayerTimes = useCallback(async (date: Date) => {
    setIsLoading(true);
    try {
      const year = getYear(date);
      const month = getMonth(date); // 0-based
      const key = `${year}-${month}`; // Unique key for each month

      if (isCurrentMonth(date)) {
        const cachedData = getCachedPrayerTimes(year, month);
        if (cachedData) {
          setPrayerTimesData(cachedData);
        } else {
          const startDate = format(startOfMonth(date), "yyyy-MM-dd");
          const endDate = format(endOfMonth(date), "yyyy-MM-dd");
          const prayerTimes = await fetchPrayerTimes(startDate, endDate);

          const formattedData: PrayerTimesData = {};
          prayerTimes.forEach((pt: PrayerTime) => {
            formattedData[pt.date] = {
              Fajr: pt.fajr,
              Sunrise: pt.sunrise,
              Dhuhr: pt.dhuhr,
              Asr: pt.asr,
              Maghrib: pt.maghrib,
              Isha: pt.isha,
            };
          });

          setPrayerTimesData(formattedData);
          setCachedPrayerTimes(year, month, formattedData);
        }
      } else {
        if (inMemoryCache.current.has(key)) {
          setPrayerTimesData(inMemoryCache.current.get(key)!);
        } else {
          const startDate = format(startOfMonth(date), "yyyy-MM-dd");
          const endDate = format(endOfMonth(date), "yyyy-MM-dd");
          const prayerTimes = await fetchPrayerTimes(startDate, endDate);

          const formattedData: PrayerTimesData = {};
          prayerTimes.forEach((pt: PrayerTime) => {
            formattedData[pt.date] = {
              Fajr: pt.fajr,
              Sunrise: pt.sunrise,
              Dhuhr: pt.dhuhr,
              Asr: pt.asr,
              Maghrib: pt.maghrib,
              Isha: pt.isha,
            };
          });

          setPrayerTimesData(formattedData);
          inMemoryCache.current.set(key, formattedData);
        }
      }
    } catch (error) {
      console.error("Error loading prayer times:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  // Load weekly prayer times
  const loadWeeklyPrayerTimes = useCallback(async () => {
    setIsWeeklyLoading(true);
    try {
      const data = await fetchWeeklyPrayerTimes();
      setWeeklyPrayerTimes(data);
    } catch (error) {
      console.error("Error fetching weekly prayer times:", error);
      setWeeklyPrayerTimes(null);
    } finally {
      setIsWeeklyLoading(false);
    }
  }, []);

  // Initial load
  useEffect(() => {
    loadPrayerTimes(new Date());
    loadWeeklyPrayerTimes();
  }, [loadPrayerTimes, loadWeeklyPrayerTimes]);

  // Update current time every second
  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date();
      setCurrentTime(now);
      if (isCurrentMonth(selectedDate)) {
        setCurrentPrayer(getCurrentPrayer(selectedDate, now, prayerTimesData));
      } else {
        setCurrentPrayer(null);
      }

      // Check if month or year changed
      const currentMonth = getMonth(now);
      const currentYear = getYear(now);
      if (
        currentMonth !== currentMonthYear.month ||
        currentYear !== currentMonthYear.year
      ) {
        setCurrentMonthYear({ month: currentMonth, year: currentYear });
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [selectedDate, prayerTimesData, currentMonthYear]);

  // Handle cache and month changes
  useEffect(() => {
    const { month, year } = currentMonthYear;
    if (isCurrentMonth(new Date())) {
      const cachedData = getCachedPrayerTimes(year, month);
      if (!cachedData) {
        loadPrayerTimes(new Date());
      }
    } else {
      clearCachedPrayerTimes();
      inMemoryCache.current.clear();
      loadPrayerTimes(new Date());
    }
  }, [currentMonthYear, loadPrayerTimes]);

  // Handle date selection
  const handleDateSelection = (date: Date): void => {
    setSelectedDate(date);
    loadPrayerTimes(date);
  };

  const nextPrayer = getNextPrayer(selectedDate, currentTime, prayerTimesData);
  const isToday = selectedDate.toDateString() === new Date().toDateString();

  const mainPageContent = isLoading ? (
    <div className="text-center text-orange-600">Loading prayer times...</div>
  ) : Object.keys(prayerTimesData).length === 0 ? (
    <div className="text-center text-orange-600">Inga bönetider tillgängliga.</div>
  ) : (
    <>
      {/* Hero Section */}
      <div className="bg-secondary rounded-lg p-6 mb-8 text-center">
        <h1 className="text-3xl sm:text-4xl font-arial-rounded-bold mb-4">
          Välkommen till Mjölby Moské
        </h1>
      </div>

      {/* News Section */}
      <NewsSection />

      {/* Main Content */}
      <div className="flex flex-col lg:flex-row gap-8 items-stretch">
        {/* Left Column: Prayer Times */}
        <div className="flex flex-col space-y-6 flex-1">
          <DateSelector
            selectedDate={selectedDate}
            onDateSelect={handleDateSelection}
          />
          <NextPrayer
            nextPrayer={nextPrayer}
            isToday={isToday}
            isLoading={isLoading}
            selectedDate={selectedDate}
          />
          {/* Wrap PrayerTimeGrid */}
          <div className="flex-grow">
            <PrayerTimeGrid
              prayerTimes={
                prayerTimesData[selectedDate.toISOString().split("T")[0]]
              }
              isLoading={isLoading}
              currentPrayer={currentPrayer}
              selectedDate={selectedDate}
            />
          </div>
        </div>

        {/* Right Column: Weekly Times */}
        <div className="flex flex-col space-y-6 flex-1">
          {/* Wrap WeeklyPrayerTimes in flex-grow */}
          <div className="flex-grow">
            <WeeklyPrayerTimes
              weeklyPrayerTimes={weeklyPrayerTimes}
              isLoading={isWeeklyLoading}
            />
          </div>
        </div>
      </div>

      {/* Quick Info Section */}
      <div className="mt-8 grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* About Section */}
        <div className="bg-secondary rounded-lg p-6 text-white">
          <h2 className="text-xl sm:text-2xl font-arial-rounded-bold mb-3">Om Mjölby Moské</h2>
          <p className="text-ml sm:text-base mb-4 font-arial-rounded">
            Mjölby Moské tjänar som ett centralt samlingsställe för den muslimska gemenskapen 
            i Mjölby och närliggande områden, öppen för alla som är intresserade av att utforska islam, 
            delta i gudstjänster eller engagera sig i vår förening.
          </p>
          <h3 className="text-lg sm:text-xl font-arial-rounded-bold mb-2">Öppettider</h3>
          <p className="text-ml sm:text-base mb-3 font-arial-rounded">
            Moskén är alltid öppen vid bönestunderna.
          </p>
          <h3 className="text-lg sm:text-xl font-arial-rounded-bold mb-2">Besöksadress</h3>
          <p className="text-ml sm:text-base font-arial-rounded">
            Hulje Östergård 1, Mjölby
          </p>
        </div>

        {/* Map Section */}
        <div className="bg-secondary rounded-lg p-6">
          <MosqueMap />
        </div>
      </div>
    </>
  );

  return (
    <div
      className={`bg-primary min-h-screen text-white font-arial-rounded flex flex-col relative ${
        isMenuOpen ? "overflow-hidden" : ""
      }`}
    >
      <Header onMenuToggle={() => setIsMenuOpen(!isMenuOpen)} />
      <div
        className={`flex-grow w-full max-w-7xl mx-auto p-4 transition-all duration-300 ${
          isMenuOpen ? "blur-sm" : ""
        }`}
      >
        <div className="max-w-7xl mx-auto lg:max-w-full lg:w-full mt-20">
          {window.location.pathname === '/kalender' ? (
            <CalendarPage
              prayerTimesData={prayerTimesData}
              onDateSelect={handleDateSelection}
            />
          ) : window.location.pathname === '/info' ? (
            <InfoPage />
          ) : window.location.pathname === '/app' ? (
            <AppDownloadPage />
          ) : (
            mainPageContent
          )}
        </div>
      </div>
      <ContactInfo />
      <SlideMenu isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
    </div>
  );
};

export default PrayerTimesApp;
